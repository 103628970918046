import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
      <div className="navbar bg-base-100 shadow-md">
        <div className="flex-1">
          <Link to="/" className="btn btn-ghost normal-case text-xl">Nick's Website</Link>
        </div>
      </div>
  );
};

export default Navbar;