import React, { useState, useEffect } from 'react';
import {googleLogout, useGoogleLogin} from '@react-oauth/google';
import axios from "axios";

const SignInWithGoogle = () => {
  const [ loading, setLoading ] = useState(true);

  const getUserInfo = (user_access_token) => {
    if (user_access_token) {
      setLoading(true);

      axios
      .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user_access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user_access_token}`,
              Accept: 'application/json'
            }
          })
      .then((res) => {
        console.log("Got user profile")
        setProfile(res.data);
      })
      .catch((err) => {
        console.log("Failed to get user profile info")
        console.log(err)
      });

      setLoading(false);
    }
  }

  const initUser = () => {
    const user_access_token = localStorage.getItem('user_access_token');
    getUserInfo(user_access_token);
  }

  const [ user, setUser ] = useState(initUser);
  const [ profile, setProfile ] = useState(null);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse)
      localStorage.setItem('user_access_token', codeResponse.access_token);
    },
    onError: (error) => console.log('Login Failed:', error)
  });

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    googleLogout();
    setProfile(null);
  };

  useEffect(
      () => {
        if (user) {
          getUserInfo(user.access_token)
        }
      },
      [ user ]
  );

  return (
      <div>
        {profile && !loading ? (
            <div className="dropdown dropdown-bottom dropdown-end">
              <div tabIndex={0} role="button" className="btn m-1">
                <div className="avatar">
                  <div className="w-8 rounded-full">
                    <img src={profile.picture} alt="user pic"/>
                  </div>
                </div>
                <p>{profile.name}</p>
              </div>
              <ul tabIndex={0} className="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow">
                <li>
                  <button onClick={logOut}>Log out</button>
                </li>
              </ul>
            </div>
        ) : (
            <button className="btn btn-active" onClick={() => login()}>Sign in with Google 🚀 </button>
        )}
      </div>
  );
};

export default SignInWithGoogle;