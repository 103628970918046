import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AttendanceTracker from './components/AttendanceTracker';
import HomePage from "./components/HomePage";
import Navbar from './components/Navbar'; // Make sure to create this file
import './App.css';
import SignInWithGoogle from "./components/SignInWithGoogle";


const themes = [
  "light", "dark", "cupcake", "bumblebee", "emerald", "corporate", "synthwave",
  "retro", "cyberpunk", "valentine", "halloween", "garden", "forest", "aqua",
  "lofi", "pastel", "fantasy", "wireframe", "black", "luxury", "dracula",
  "cmyk", "autumn", "business", "acid", "lemonade", "night", "coffee",
  "winter", "dim", "nord", "sunset"
];

function App() {
  const [theme, setTheme] = useState(() => {
    return localStorage.getItem('theme') || "cupcake";
  });

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
      <BrowserRouter>
        <div className="flex flex-col min-h-screen">
          <Navbar />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/attendance" element={<AttendanceTracker />} />
              {/* Other routes */}
            </Routes>
          </main>
          <div className="fixed top-2 right-4 z-50">
            <SignInWithGoogle/>
          </div>
          <div className="fixed bottom-4 right-4 z-50">
            <select
                className="select select-bordered select-sm"
                value={theme}
                onChange={(e) => setTheme(e.target.value)}
            >
              {themes.map((t) => (
                  <option key={t} value={t} className="text-base font-semibold theme-option">
                    {t.charAt(0).toUpperCase() + t.slice(1)}
                  </option>
              ))}
            </select>
          </div>
        </div>
      </BrowserRouter>
  );
}

export default App;