import React, { useState, useEffect, useCallback } from 'react';
import queryString from 'query-string';
import { format, addDays, subDays, parse } from 'date-fns';
import {Helmet} from "react-helmet";
import config from "../config";

const AttendanceTracker = () => {
  const getInitialDate = () => {
    const params = queryString.parse(window.location.search);
    return params.date ? parse(params.date, 'yyyy-MM-dd', new Date()) : new Date();
  };

  const [meetingData, setMeetingData] = useState({
    date: getInitialDate(),
    startTime: '09:00',
    endTime: '09:00',
    runBy: '',
    attendees: [
      { name: 'Nick', present: false },
      { name: 'Tyler', present: false },
      { name: 'Alex', present: false },
      { name: 'Samir', present: false },
      { name: 'Shriman', present: false },
      { name: 'Rodrigo', present: false },
      { name: 'Seema', present: false },
      { name: 'Ryan', present: false },
      { name: 'Mohit', present: false },
      { name: 'Chad', present: false },
      { name: 'Kyle', present: false },
    ],
    status: 'new', // Can be 'new', 'existing', or 'noData'
  });

  const [submitState, setSubmitState] = useState({
    isSubmitting: false,
    message: '',
  });

  const fetchAttendance = useCallback(async (fetchDate) => {
    try {
      const response = await fetch(`${config.API_URL}/api/get-attendance?date=${format(fetchDate, 'yyyy-MM-dd')}`);
      if (response.ok) {
        const data = await response.json();
        setMeetingData(prevData => ({
          ...prevData,
          ...data,
          date: fetchDate,
          status: 'existing',
          attendees: prevData.attendees.map(attendee => ({
            ...attendee,
            present: data.attendees.find(a => a.name === attendee.name)?.present || false
          }))
        }));
      } else if (response.status === 404) {
        setMeetingData(prevData => ({
          ...prevData,
          date: fetchDate,
          startTime: '09:00',
          endTime: '09:00',
          runBy: '',
          status: 'noData',
          attendees: prevData.attendees.map(a => ({ ...a, present: false }))
        }));
      }
    } catch (error) {
      console.error('Error fetching attendance:', error);
      setMeetingData(prevData => ({
        ...prevData,
        date: fetchDate,
        status: 'noData'
      }));
    }
  }, []);

  const handleDateChange = (newDate) => {
    const dateStr = format(newDate, 'yyyy-MM-dd');
    const newUrl = `${window.location.pathname}?${queryString.stringify({ date: dateStr })}`;
    window.history.pushState({ date: dateStr }, '', newUrl);
    fetchAttendance(newDate);
    setSubmitState(prevState => ({ ...prevState, message: '' }));
  };

  const handlePreviousDay = () => {
    handleDateChange(subDays(meetingData.date, 1));
  };

  const handleNextDay = () => {
    handleDateChange(addDays(meetingData.date, 1));
  };

  const toggleAttendance = useCallback((index) => {
    setMeetingData(prevData => {
      const newAttendees = [...prevData.attendees];
      newAttendees[index] = { ...newAttendees[index], present: !newAttendees[index].present };
      return { ...prevData, attendees: newAttendees };
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitState({ isSubmitting: true, message: '' });

    const presentAttendees = meetingData.attendees.filter(a => a.present);

    try {
      const response = await fetch(`${config.API_URL}/api/submit-attendance`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          date: format(meetingData.date, 'yyyy-MM-dd'),
          startTime: meetingData.startTime.substring(0, 5),  // Only send HH:MM
          endTime: meetingData.endTime.substring(0, 5),  // Only send HH:MM
          runBy: meetingData.runBy,
          attendees: presentAttendees,
        }),
      });

      if (response.ok) {
        setSubmitState({
          isSubmitting: false,
          message: meetingData.status === 'existing'
              ? 'Attendance updated successfully!'
              : 'Attendance submitted successfully!'
        });
        setMeetingData(prevData => ({ ...prevData, status: 'existing' }));
      } else {
        const errorData = await response.json();
        console.error('Server responded with an error:', errorData);
        setSubmitState({
          isSubmitting: false,
          message: `Error submitting attendance: ${errorData.message || 'Unknown error'}`
        });
      }
    } catch (error) {
      console.error('Error submitting attendance:', error);
      setSubmitState({
        isSubmitting: false,
        message: `Error submitting attendance: ${error.message || 'Unknown error'}`
      });
    }
  };

  useEffect(() => {
    const handlePopState = (event) => {
      if (event.state && event.state.date) {
        fetchAttendance(new Date(event.state.date));
      }
    };

    window.addEventListener('popstate', handlePopState);

    // Fetch attendance for the initial date
    const params = queryString.parse(window.location.search);
    const initialDate = params.date ? parse(params.date, 'yyyy-MM-dd', new Date()) : new Date();
    fetchAttendance(initialDate);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [fetchAttendance]);

  return (
      <div className="bg-base-200 min-h-screen py-8">
        <div className="max-w-3xl mx-auto bg-base-100 shadow-xl rounded-lg p-6">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Attendance Tracker</title>
            <link rel="canonical" href="http://budoso.com/attendance" />
          </Helmet>
          <div className="text-center mb-8">
            <h1 className="text-3xl font-bold">Meeting Attendance Tracker</h1>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="flex justify-between items-center mb-6">
              <button type="button" onClick={handlePreviousDay} className="btn btn-primary">&lt; Previous</button>
              <input
                  type="date"
                  value={format(meetingData.date, 'yyyy-MM-dd')}
                  onChange={(e) => handleDateChange(new Date(e.target.value))}
                  className="input input-bordered"
              />
              <button type="button" onClick={handleNextDay} className="btn btn-primary">Next &gt;</button>
            </div>
            <div className="grid grid-cols-3 gap-4 mb-6">
              <div>
                <label htmlFor="start-time" className="label">Start Time</label>
                <input
                    id="start-time"
                    type="time"
                    value={meetingData.startTime}
                    onChange={(e) => setMeetingData(prevData => ({ ...prevData, startTime: e.target.value }))}
                    className="input input-bordered w-full"
                />
              </div>
              <div>
                <label htmlFor="end-time" className="label">End Time</label>
                <input
                    id="end-time"
                    type="time"
                    value={meetingData.endTime}
                    onChange={(e) => setMeetingData(prevData => ({ ...prevData, endTime: e.target.value }))}
                    className="input input-bordered w-full"
                />
              </div>
              <div>
                <label htmlFor="run-by" className="label">Run By</label>
                <select
                    id="run-by"
                    value={meetingData.runBy}
                    onChange={(e) => setMeetingData(prevData => ({ ...prevData, runBy: e.target.value }))}
                    className="select select-bordered w-full"
                >
                  <option value="">Select...</option>
                  {meetingData.attendees.map(attendee => (
                      <option key={attendee.name} value={attendee.name}>
                        {attendee.name}
                      </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <h2 className="text-xl font-semibold mb-4">Attendees</h2>
              <div className="grid grid-cols-3 gap-4 mb-6">
                {meetingData.attendees.map((attendee, index) => (
                    <div key={attendee.name} className="form-control">
                      <label className="cursor-pointer label justify-start">
                        <input
                            type="checkbox"
                            checked={attendee.present}
                            onChange={() => toggleAttendance(index)}
                            className="checkbox checkbox-primary mr-2"
                        />
                        <span className="label-text">{attendee.name}</span>
                      </label>
                    </div>
                ))}
              </div>
            </div>
            <div className="text-center">
              {meetingData.status === 'noData' && <p className="text-error">No meeting data for this date.</p>}
              <button type="submit" disabled={submitState.isSubmitting} className="btn btn-primary btn-wide">
                {submitState.isSubmitting
                    ? 'Submitting...'
                    : meetingData.status === 'existing'
                        ? 'Update Attendance'
                        : 'Submit Attendance'}
              </button>
              {submitState.message && <p className="mt-2 text-info">{submitState.message}</p>}
            </div>
          </form>
        </div>
      </div>
  );
};


export default AttendanceTracker;