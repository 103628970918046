import { Link } from "react-router-dom";

const HomePage = () => {
  return (
      <div className="min-h-screen bg-base-200">
        <div className="hero bg-base-100">
          <div className="hero-content text-center">
            <div className="max-w-md">
              <h1 className="text-5xl font-bold mb-5">Nick's Website</h1>
              <p className="mb-5">This is a place for me to mess around and push things to the web.</p>
            </div>
          </div>
        </div>

        <div className="container mx-auto px-4 py-8">
          <h2 className="text-3xl font-semibold mb-4">My Projects</h2>
          <div className="card bg-base-100 shadow-xl">
            <div className="card-body">
              <ul className="menu bg-base-200 w-56 rounded-box">
                <li>
                  <Link to="/attendance" className="text-lg">
                    <div className="m-auto">
                      Attendance Tracker
                    </div>
                  </Link>
                </li>
                {/* Add more project links here */}
              </ul>
            </div>
          </div>
        </div>
      </div>
  );
};

export default HomePage;